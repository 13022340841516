import React from "react";


const Home = () => {

  return (
    <div className="container__bg">
      <h1 className="text__home text-9xl font-bold select-none">PLAY MOVIE</h1>
      <div className="container__bg--url"></div>
    </div>
  );
};

export default Home;
